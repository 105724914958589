* {
    margin: 0;
    padding: 0;
    font-family: "JetBrains Mono", monospace;
    /* overflow-x: hidden; */
}

/* a:hover {
    color: inherit;
} */

.hide-scroll {
    overflow: hidden !important;
}

.box-img {
    width: 80px;
    padding: 10px;
}

.width-80 {
    width: 67% !important;
}

.height-auto {
    height: auto !important;
}

.img-thumbnail-res {
    max-width: 80%;
    height: 100%;
}

@media only screen and (max-width: 1000px) {
    .navbar-collapse.show {
        /* background-color: rgba(0, 0, 0, 0.6) !important; */
        margin-top: 20px;
    }

    .mySwiper .swiper-slide {
        width: 100% !important;
    }

    .box-img {
        width: 63px;
    }
}

.nav-link-border {
    border: 2px solid #fff;
}

.nav-link.active {
    border-radius: 15px;
    border: 2px solid #0d6efd;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

}

.nav-bar {
    backdrop-filter: blur(4px) !important;
    background-color: rgba(0, 0, 0, 0.6) !important;
    height: 100px;
    width: 100%;
    overflow: hidden;
}



.nav-container {
    padding: 0 !important;
}

.logo-img {
    width: 130px;
}

.nav-list {
    font-family: 'JetBrains Mono', Regular;
    font-size: 16px !important;
    color: #fff;
    background-color: inherit;
    position: relative;
    text-decoration: none;
    padding: 0 1rem;
}



.active-list::after {
    content: '';
    display: block;
    color: #0d6efd !important;
    width: 18px;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    margin: 0 auto;
    margin-top: 3px;
    text-decoration: none !important;
}

/* Nav .active::after {
    content: '';
    display: block;
    color: #0d6efd !important;
    width: 18px;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    margin: 0 auto;
    margin-top: 3px;
} */

.active-navList::after {
    content: '';
    display: block;
    color: #0d6efd !important;
    width: 18px;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    margin: 0 auto;
    margin-top: 3px;
}

.nav-list:hover {
    color: #fff !important;
}


.nav-list.actives::after {
    content: '';
    display: block;
    color: #0d6efd !important;
    width: 18px;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    margin: 0 auto;
    margin-top: 3px;
}

.tech-btn-grp {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

/* Setting the stroke to green using rgb values (0, 128, 0) */

.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 128, 0, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.nv-respo-colapse {
    overflow: visible;
}

.nav-talk {
    overflow: visible;
}

.nv-letstalk {
    border: 2px solid #9528ED;
    border-radius: 20px;
    font-family: 'JetBrains Mono', ExtraBold;
    font-size: 14px !important;
    color: #fff !important;
    position: relative;
    cursor: pointer;
    overflow: visible;
    margin: 10px;
}


.ldpg {
    width: 100%;
    /* position: absolute !important; */
    top: 0;
}

.lticon {
    position: absolute;
    /* top: 28px;
    right: 100px; */
    top: -4px;
    right: -12px;
}

.slide-sw {
    width: 100px !important;
}

.mySwiper .swiper-slide {
    width: 800px !important;
}

.mySwiper1 .swiper-slide {
    color: white;
    text-align: center;
    padding-bottom: 70px;
}

.mySwiper1 {
    width: 50vw;
}

.mySwiper1 .swiper-pagination-bullet {
    background: grey !important;
}

.mySwiper3 {
    width: 100%;
    justify-content: center;
}

.mySwiper3 .swiper-wrapper {
    justify-content: center;
}

.mySwiper3 .swiper-slide {
    width: 150px !important;
}

.mySwiper1 .swiper-pagination-bullet {
    background: grey !important;
}

/* .mySwiper .swiper-slide-active{
    width: 800px !important;
}
.mySwiper .swiper-slide-active img{
    width: 800px !important;
} */
.her-bg-image {
    background: url("../assets/moaisus-assets-webp/homeImg.webp");
    background-size: cover;
}

.h-c-100 {
    height: 100vh;
}

.primaryColor {
    color: #62bcf3 !important;
}

.primary-color-border {
    border-width: 1px;
    border-style: solid;
    border-color: #62bcf3;
    border-radius: 10px;
}

.btn-padding {
    padding: 7px;
}

.custom-padding {
    margin: 0 100px;
}

.btn-group {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    width: 800px !important;
}

.btn-outline-primary {
    --bs-btn-color: #000 !important;
    --bs-btn-border-color: #0d6efd;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0d6efd;
    --bs-btn-hover-border-color: #0d6efd;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #0d6efd;
    --bs-btn-active-border-color: #0d6efd;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #0d6efd;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #0d6efd;
    --bs-gradient: none;
}

.btn:hover {
    color: #9528ED !important;
    background-color: #fff !important;
    border-color: var(--bs-btn-hover-border-color);
}

.testimonialMain {
    background: url("../assets/moaisus-assets-webp/Main Background@2x.webp");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.bg-black {
    background-color: #000;
}

.pm-5 {
    margin-top: -2px;
}

/* About Us Styling */
.about-container {
    position: relative;
    display: flex;
}

.back-img {
    width: 100%;
}

.privacy-heading {
    margin-top: 8rem;
    font-weight: 700;
}

.heading {
    color: #fff !important;
    position: absolute;
    width: 100%;
    font-family: 'JetBrains Mono', Regular;
    background-color: rgba(0, 0, 0, 0.6);
    font-size: 38px;
    top: 200px;
}

.down-img {
    /* background-color: black; */
    position: absolute;
    bottom: 30px;
    left: 20px;
}

.our-team-container {
    max-width: 1200px;
}

.img-cont {
    position: relative;
    left: 50%;
}

.bg-red {
    background-color: red;
    width: 150px !important;
    height: 200px;
    position: absolute;
    top: 15px;
    right: -10px;

    border-radius: 12px;

}

.card-imgs {
    border-radius: 12px;
    width: 150px !important;
    height: 200px;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;

}

.card-details {
    margin-top: 235px;
    margin-right: 18px;
}

.cursor {
    cursor: pointer;
}

.btn-txt:hover {
    color: #9528ED !important;
    font-family: "JetBrains Mono", monospace;
}

.btn-txt-selected {
    color: #9528ED !important;
    font-family: "JetBrains Mono", monospace;

}

.btn-m {
    margin-left: 5%;
    margin-right: 5%;
}

.grp-btn-border {
    border-width: 1.9px;
    border-style: solid;
    border-color: #0d6efd;
    border-radius: 10px;

}

.grp-btn-border-right-line {
    /* border-width: 1.5px; */
    /* border-style: solid; */
    border-right: 1px ridge #0d6efd;
    right: 0;
    /* border-color: #0d6efd;
    border-right :ridge; */

}

.bet-cont {
    width: 700px;
}

.img-responsive {
    width: 100%;
    height: auto;
}

.line {
    border-bottom: 1px solid #0d6efd;
    width: 100%;
}

.read-more-less--more {
    background-color: transparent !important;
    color: #fff !important;
    font-weight: 900 !important;
}

.read-more-less--less {
    background-color: transparent !important;
    color: #fff !important;
    font-weight: 900 !important;
}

.text-justify {
    text-align: justify;
}

@media only screen and (min-width: 100px) and (max-width: 576px) {
    #work {
        margin: 0 !important;
    }

    .logo-img {
        width: 100px;
    }

    .cursor.btn-txt {
        font-size: 12px !important;
    }

    .btn-txt-selected {
        font-size: 12px !important;

    }

    .nav-bar {
        height: inherit;
    }

    .heading {
        font-size: 12px !important;
        padding: 10px 20px !important;
        top: 100px !important;
        margin-top: 20px !important;
    }

    .down-img {
        display: none;
    }

    .nav-list {
        padding: 10px 0;
    }

    .my-5.px-md-5.px-lg-5.px-xl-5.px-4.custom-padding {
        margin: 0 !important;
    }

    .mySwiper .swiper-slide {
        width: 100% !important;
    }

    .mySwiper .swiper-slide {
        width: 100% !important;
    }



    .swslide {
        width: 95% !important;
    }

    .swslide2 {
        width: 90% !important;
    }

    .h6.sp-text {
        font-size: .7rem;
        text-align: center;
        color: #000 !important;
    }

    .box-img {
        width: 50px !important;
    }

    .bet-cont {
        width: 90%;
    }

    .op-content-imgs {
        width: 100%;
        height: 100%;
        padding: 0 20px;
    }

    .mySwiper3 .swiper-wrapper {
        justify-content: unset !important;
    }

    .mySwiper1 {
        width: 70vw;
    }

    .footerLink {
        flex-direction: column;
        padding: 1rem 0;
        /* align-items: start !important; */
    }
}


@media only screen and (min-width: 576px) and (max-width: 768px) {
    .logo-img {
        width: 100px;
    }

    .nav-bar {
        height: inherit;

    }

    .heading {
        padding: 10px 20px !important;
        top: 150px !important;
        font-size: 22px !important;

    }

    .down-img {
        height: 70px;
    }

    .my-5.px-md-5.px-lg-5.px-xl-5.px-4.custom-padding {
        margin: 0 !important;
    }

    .mySwiper .swiper-slide {
        width: 100% !important;
    }

    .swslide {
        width: 88% !important;
    }

    .swslide2 {
        width: 80% !important;
    }

    .bet-cont {
        width: 90%;
    }

    .mySwiper1 {
        width: 50vw;
    }

    .mySwiper3 .swiper-wrapper {
        justify-content: unset !important;
    }

}

@media all and (min-width: 768px) and (max-width: 992px) {
    .nav-bar {
        height: inherit;
    }

    .heading {
        font-size: 28px !important;
        padding: 10px 20px !important;
        top: 150px !important;
    }

    .mySwiper .swiper-slide {
        width: 100% !important;
    }

    .mySwiper .swiper-slide {
        width: 100% !important;
    }

    .swslide {
        width: 88% !important;
    }

    .swslide2 {
        width: 80% !important;
    }

    .box-img {
        width: 63px !important;
    }

    .bet-cont {
        width: 90%;
    }

    .mySwiper3 .swiper-wrapper {
        justify-content: unset !important;
    }

}


.pm-5 {
    margin-top: -2px;
}

.swiper-button-next {
    background-image: url("../assets/Moaisus-assets/Home/LandingPage/Right Arrow@2x.png");
    width: 60px !important;
    height: 60px !important;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    margin-right: 5%;
}

.swiper-button-next::after {
    margin-right: 100px;
}

.swiper-button-next::after {
    display: none;
}

.swiper-button-prev {
    background-image: url("../assets/Moaisus-assets/Home/LandingPage/Left Arrow@2x.png");
    width: 60px !important;
    height: 60px !important;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    margin-left: 5%;
}

.swiper-button-prev::after {
    margin-left: 100px;
}

.swiper-button-prev::after {
    display: none;
}

.highlight-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minmax(150px, auto);
    margin: 0 auto;
    grid-template-areas:
        "sg sg sg db"
        "td td td td";
    grid-gap: 10px;
}

.betcard {
    min-height: 124px;
    justify-content: center;
}

/* .single img {
    width: 98%;
    height: 98%;
} */
.single {
    grid-area: sg;
}

.third {
    grid-area: td;
}

.double {
    grid-area: db;
    justify-content: space-between;
}

.single img {
    width: 97%;
    height: 100%;
    max-height: 700px;
}

.double img {
    width: 110%;
    height: 48% !important;
}

.third img {
    width: 101%;
}

/* .single {
    grid-area: sg;
    background-image: url('../assets/Moaisus-assets/Home/Work\ Highlight/Image - 1@2x.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    height: 50vw;
    border: 3px;
}

.double1 {
    background-image: url('../assets/Moaisus-assets/Home/Work\ Highlight/Image - 2.png');
    background-repeat: no-repeat;
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%;
    min-height: 23vw;
    width: 100%;
}

.double2 {
    background-image: url('../assets/Moaisus-assets/Home/Work\ Highlight/Image2.png');
    background-repeat: no-repeat;
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%;
    min-height: 23vw;
    width: 100%;
}



.third {
    grid-area: td;
    background-image: url("../assets/Moaisus-assets/Home/Work Highlight/Image - 4.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    min-height: 40vw;
    border-radius: 3px;
} */

.wd-flx-cont {
    justify-content: space-between !important;
    align-items: unset !important;
}

/* .cardssss img {
    display: block;
    margin: auto;
    margin-right: auto;
    width: 70%;
} */


/* h2 {
    margin: 0 !important;
} */

.prod-crd {
    flex: 0 0 auto !important;
    font-size: 12px;
}

.prod-crd img {
    display: none;
}

.minus-px {
    margin-top: -89px !important;
}

.read-more-less--more {

    background-color: #fff;
    color: #000;
}

.read-more-less--less {
    background-color: #fff;
    color: #000;
}

.op-content-imgs {
    max-width: 400px;
    max-height: 400px;
}

.VideoTag {
    top: 90px;
    position: absolute;
}

.tablet-img {
    position: relative;
    max-height: 470px !important;
}

.trusted-container {
    justify-content: center !important;
}