* {
  margin: 0;
  padding: 0;
}

body {
  background: transparent;
}

.login {
  background: white;
  border-radius: 10px;
}

/* ul {
  list-style: none;
} */

.first-text {
  display: flex;
  justify-content: center;
  font-family: "JetBrains Mono", monospace;
  color: white;
}

.second-text {
  display: flex;
  justify-content: center;
  font-family: "JetBrains Mono", monospace;
  color: #59b9f1;
  padding-bottom: 25px;
}

.login-name {
  width: 300px;
  height: 40px;
  background-color: transparent;
  font-family: "Raleway", sans-serif;
  border: 0px solid;
  color: rgb(0, 0, 0);
  border-bottom: 1px solid rgb(190, 190, 190);
}

.login-email {
  width: 655px;
  height: 40px;
  background-color: transparent;
  border: 0px solid;
  font-family: "Raleway", sans-serif;
  color: rgb(0, 0, 0);
  border-bottom: 1px solid rgb(190, 190, 190);
}

.login-textarea {
  padding-top: 10px;
  width: 655px;
  height: 80px;
  background-color: transparent;
  border: 0px solid;
  color: rgb(0, 0, 0);
  font-family: "Raleway", sans-serif;
  border-bottom: 1px solid rgb(190, 190, 190);
}

.login-name:focus {
  outline: none;
}

.login-email:focus {
  outline: none;
}

.login-textarea {
  outline: none;
}

.flex {
  display: flex;
  /* justify-content: space-between; */
}

input::placeholder {
  font-family: "Raleway", sans-serif;
  color: rgb(210, 210, 210);
}

textarea::placeholder {
  font-family: "Raleway", sans-serif;
  color: rgb(210, 210, 210);
}

.name-padding {
  padding-left: 50px;
  padding-top: 20px;
}

.email-padding {
  padding-left: 50px;
  padding-top: 15px;
}

select:invalid {
  font-family: "Raleway", sans-serif;
  color: rgb(210, 210, 210);
}

.button {
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 20px;
  padding-top: 25px;
  display: flex;
  justify-content: space-between;
}

.button-text {
  font-family: "JetBrains Mono", monospace;
  font-size: 15px;
  width: 150px;
  height: auto;
  border: none;
  border-radius: 50px;
  color: white;
  background-color: #9528ed;
}

.form-label {
  font-family: "Raleway", sans-serif;
  font-size: 15px;
}

.text-align {
  color: #e16a33;
  font-family: "Raleway", sans-serif;
  text-decoration: none;
}

.d-flex {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.back {
  padding-left: 5px;
  font-family: "Raleway", sans-serif;
  color: #e16a33;
}

select:valid {
  color: black;
}

.form-option {
  color: black;
}

@media screen and (max-width: 1000px) {
  form .all-inputs {
    width: 100%;
  }

  .email-padding {
    padding-left: 0px;
  }

  .button {
    padding-left: 0px;
    padding-right: 0px;
  }

  .name-padding {
    padding-left: 0px;
  }
}

/* scrollbar */
::-webkit-scrollbar {
  width: 10px;
  padding: 2px;
}

::-webkit-scrollbar-thumb {
  background: rgb(160, 160, 160);
  border-radius: 5px;
}

/* Modal CSS */
.modal-content {
  background-color: rgba(0, 0, 0, 0) !important;
  border: none !important;
}

.modal-body {
  background-color: #fff;
  border-radius: 10px;
}

.modal {
  backdrop-filter: blur(30px);
  background-color: rgba(0, 0, 0, 0);
  background: transparent !important;
}

.modal-head {
  display: block;
  align-content: center;
  align-items: center;
}

/* .container {
  padding: 0 !important;
} */

.form-error {
  font-size: 13px;
  color: #b22b27;
  font-family: "Raleway", sans-serif;
}

.toastProgress {
  font-family: "Raleway", sans-serif;
}

/* social icons */

.icon-bar {
  position: fixed;
  /* background: #e9f5fd30; */
  background: inherit;
  padding: 5px;
  border-radius: 3px;
  top: 50%;
  right: 0%;
  transform: translateY(-50%);
  z-index: 10;
}

.icon-img {
  width: 30px;
}

.facebook,
.twitter,
.linkedin,
.whatsapp {
  display: block;
  padding: 1px;
}

/* footer */
@media screen and (max-width: 1000px) {
  .parent-footer {
    margin: 0;
  }

  .child-footer {
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .img-footer {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;
  }

  .letstalk-footer {
    width: 300px;
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.footer-link {
  text-decoration: none;
}